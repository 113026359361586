<template>
  <div class="Search">
    <ApplicationsLanding v-if="showSearchResults" />
  </div>
</template>

<script>
import ApplicationsLanding from './ApplicationsLanding'

export default {
  components: {
    ApplicationsLanding,
  },
  inject: ['store'],
}
</script>

<style lang="scss">
.Search.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
