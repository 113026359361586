<template>
  <div>
    <AppCardGrid />
  </div>
</template>
<script>
import AppCardGrid from '../components/Assort/AppCardGrid'
export default {
  inject: ['store'],
  gridMode: 'grid',
  components: {
    AppCardGrid,
  },
  data() {
    return {
      applications: [],
    }
  },
  computed: {
    useAssort() {
      switch (this.store.router.path) {
        case '/userRVT/applications':
          return 'global'
        default:
          return 'global'
      }
    },
    viewAssort() {
      switch (this.store.router.path) {
        case '/userRVT/applications':
          return 'global'
        default:
          return 'global'
      }
    },
  },
  mounted() {
    // this.store.seasons.setCurrentSeason('483-S1-2021-663-674')
  },
}
</script>
