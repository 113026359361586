<template>
  <div class="assort-wrapper">
    <section v-if="!isEmpty && !isError" :class="sectionHeight">
      <div :ref="'mainPanel'" class="mainPanel AssortPanel">
        <div class="mainPanelActionsGrid">
          <div
            v-if="isSearch"
            class="pt-2 text-blue-search font-bold mt-6 underline"
          >
            Search Results
          </div>
          <div v-else class="pt-2"></div>
          <div class="pt-8" style="position: relative">
            <div
              class="
                flex-end flex
                justify-end
                lg:h-auto
                mainPanelActionButtonsAssort
              "
            >
              <button
                class="CTAButton bg-transparent flex items-center"
                @click="confirmNewAssortment"
              >
                Add New Application
              </button>
            </div>
          </div>
        </div>

        <div
          :class="[
            { fade: !isReady },
            { mainPanelGridBottom: gridMode !== 'grid' },
            { mainPanelGridBottomCard: gridMode === 'grid' },
          ]"
          class="mainPanelGrid"
        >
          <CardGrid
            :key="`user-assortment_${componentKey}`"
            :assortments="renderedLooks"
            :mode="gridMode"
            :submode="submode"
            :use="use"
            :sorted="sorted"
          />
        </div>
        <Spinner v-if="!isReady" class="spinner stretch" />
      </div>
    </section>
  </div>
</template>

<script>
import CardGrid from './CardGrid'
import Spinner from '../Spinner.vue'

import { affiliates, regions, brand } from '../../util/valueHelpers'

export default {
  name: 'AssortCardGrid',
  components: {
    CardGrid,
    Spinner,
  },
  props: {
    remount: {
      default: false,
      required: false,
      type: Boolean,
    },
    use: {
      default: '',
      required: false,
      type: String,
    },
    view: {
      default: 'global',
      required: false,
      type: String,
    },
  },
  data() {
    return {
      affiliatesHelper: affiliates,
      brandHelper: brand,
      currentFilters: [],
      currentSelectedLook: '',
      draggedLooks: [],
      file: null,
      filterableAttributes: ['lscoDemandAttr'],
      filtersOpen: false,
      filtersReady: false,
      formFunction: 'ADD PC9',
      // formKey: 0,
      lookModal: false,
      looks: [],
      masterFilters: [],
      panelActions: null,
      panelActionsTop: null,
      regionsHelper: regions,
      renderedFilters: [],
      renderedLooks: [],
      secondClick: 0,
      sorted: false,
      submode: 'view',
      compareList: {},
      users: [],
    }
  },
  computed: {
    affiliateItemWidth() {
      const affArr = this.affiliateOptions

      const longestItem = Math.max(...affArr.map(affil => affil.value.length))

      let itemsWidth = longestItem * 8
      itemsWidth += 10
      if (itemsWidth >= 291) {
        itemsWidth = 290
      }
      return `${itemsWidth}px`
    },
    affiliateOptions() {
      const affiliateArr = this.store.addGlobalAssortment.assortmentAffiliates

      let affiliateObj = affiliateArr.reduce(
        (ac, a) => ({ ...ac, [a]: this.affiliatesHelper(a) }),
        {},
      )
      var output = Object.entries(affiliateObj).map(([key, value]) => ({
        key,
        value,
      }))

      return output
    },
    affiliateSelected() {
      return this.store.addGlobalAssortment.affiliate
    },
    affiliateSelectedValue() {
      const affiliateSel = this.store.addGlobalAssortment.affiliate
      let obj = this.affiliateOptions.find(o => o.key === affiliateSel)
      if (typeof obj !== 'undefined') {
        return obj.value
      }
      return ''
    },
    assortBrand() {
      return this.store.addGlobalAssortment.brandName
    },
    assortName() {
      return this.store.addGlobalAssortment.name
    },
    assortSeason() {
      return this.store.addGlobalAssortment.season
    },
    assortYear() {
      return this.store.addGlobalAssortment.year
    },
    breadCrumbName() {
      if (localStorage.getItem('currAssortName')) {
        let currName = localStorage.getItem('currAssortName')
        return this.shorten(currName, 30)
      }
      return 'View'
    },
    carryMode() {
      return this.store.addGlobalAssortment.carryMode
    },
    copyMode() {
      return this.store.addGlobalAssortment.copyMode
    },
    componentKey() {
      if (this.use === 'line') {
        return this.store.userAssortments.componentKey
      } else {
        if (this.use === 'search') {
          return this.store.searchAssortments.componentKey
        } else {
          return this.store.assort.componentKey
        }
      }
    },
    confirmText() {
      return 'SAVE'
    },
    editLinkUrl() {
      return {
        path: '/search',
        query: this.$route.query,
      }
    },
    editMode() {
      return this.store.addGlobalAssortment.editMode
    },
    editRows() {
      return this.store.addGlobalAssortment.editRows
    },
    formKey() {
      return this.store.addGlobalAssortment.formKey
    },
    formTitle() {
      return `${this.formFunction} ${this.assortName} ${this.assortYear} ${this.assortSeason} ${this.assortBrand}`
    },
    gridHeight() {
      return this.store.assort.gridHeight
    },
    gridHeightWindow() {
      return this.store.assort.windowHeightSaved
    },
    gridCardHeight() {
      return this.store.assort.gridCardHeight
    },
    gridHeightWindowCard() {
      return this.store.assort.windowHeightSavedCard
    },
    gridMode() {
      return this.store.addGlobalAssortment.currentGridView
    },
    isEmpty() {
      return false
    },
    isError() {
      return false
    },
    isReady() {
      if (this.use === 'line') {
        return this.store.userAssortments.isReady
      } else {
        if (this.use === 'search') {
          return this.store.searchAssortments.isReady
        } else {
          return this.store.assort.isReady
        }
      }
    },
    isSearch() {
      if (this.store.router.path === '/search') {
        return true
      }
      return false
    },
    paletteMode() {
      return this.store.addGlobalAssortment.paletteMode
    },
    regionItemWidth() {
      const regArr = this.regionOptions
      const longestItem = Math.max(...regArr.map(affil => affil.value.length))

      const itemsWidth = longestItem * 10

      return `${itemsWidth}px`
    },
    regionOptions() {
      const regionArr = this.store.addGlobalAssortment.assortmentRegions

      let regionObj = regionArr.reduce(
        (ac, a) => ({ ...ac, [a]: this.regionsHelper(a) }),
        {},
      )
      var output = Object.entries(regionObj).map(([key, value]) => ({
        key,
        value,
      }))

      return output
    },
    regionSelected() {
      return this.store.addGlobalAssortment.region
    },
    regionSelectedValue() {
      const regionSel = this.store.addGlobalAssortment.region
      let obj = this.regionOptions.find(o => o.key === regionSel)
      if (typeof obj !== 'undefined') {
        return obj.value
      }
      return ''
    },
    seasonOptions() {
      const seasonOptionsObjArr = this.store.seasonSelector.seasonOptions
      const seasonOptionsArray = seasonOptionsObjArr.map(obj => {
        return obj.value
      })
      return seasonOptionsArray
    },
    seasonSelected() {
      return this.store.addGlobalAssortment.season
    },
    sectionHeight() {
      if (this.gridMode === 'list') {
        return 'sectionHeightList'
      }

      return 'sectionHeight'
    },
    sectionName() {
      return this.$route.params.currentSection
    },
    selectedRows() {
      return this.store.addGlobalAssortment.selectedRows
    },
    showRegion() {
      if (['region', 'affiliate'].includes(this.view)) {
        return true
      }
      return false
    },
    yearOptions() {
      const yearOptionsObjArr = this.store.seasonSelector.yearOptions
      const yearOptionsArray = yearOptionsObjArr.map(obj => {
        return obj.value
      })
      return yearOptionsArray
    },
    yearSelected() {
      return this.store.addGlobalAssortment.year
    },
    compareMode() {
      return this.store.addGlobalAssortment.compareMode
    },
  },
  inject: ['store'],
  watch: {
    isReady(newVal, oldVal) {
      if (newVal) {
        this.looks = this.store.assort.items
        this.renderedLooks = [...this.looks]
      }
    },
    looks(newVal, oldVal) {
      if (this.use === 'line') {
        return (this.store.userAssortments.componentKey += 1)
      } else {
        if (this.use === 'search') {
          return (this.store.searchAssortments.componentKey += 1)
        } else {
          return (this.store.assort.componentKey += 1)
        }
      }
    },
    gridHeight(newVal, oldVal) {
      if (this.isReady) {
        if (this.gridMode === 'list') {
          this.setGridHeight()
        } else {
          this.setGridCardHeight()
        }
      }
    },
    gridCardHeight(newVal, oldVal) {
      if (this.isReady) {
        if (this.gridMode === 'list') {
          this.setGridHeight()
        } else {
          this.setGridCardHeight()
        }
      }
    },
  },
  mounted() {
    this.store.assortUser.displayMetaData = false
    this.store.addGlobalAssortment.editMode = false
    this.store.addGlobalAssortment.carryOver = false
    this.store.addGlobalAssortment.carryMode = false
    this.store.addGlobalAssortment.showStatusReport = false
    // this.store.addGlobalAssortment.pc9status = false
    // this.store.addGlobalAssortment.pc9sadded = false
    this.store.addGlobalAssortment.assortType = this.view
    localStorage.setItem('currAssortType', this.view)
    this.store.addGlobalAssortment.visibility = this.view
    localStorage.setItem('currVisibility', this.view)
    // this.store.addGlobalAssortment.region = this.view

    // this.formKey += 1
    let localLookupHub = 'none'

    if (this.view === 'affiliate') {
      localLookupHub = this.store.addGlobalAssortment.affiliate
    }
    if (this.view === 'region') {
      localLookupHub = this.store.addGlobalAssortment.region
    }
    if (this.view === 'global') {
      localLookupHub = this.store.addGlobalAssortment.region
    }
    if (this.view === 'personal') {
      localStorage.setItem('currVisibility', 'personal')
      this.store.addGlobalAssortment.visibility = 'personal'
    }
    // if (this.store.addGlobalAssortment.affiliate)
    // if (localStorage.getItem('lookupHub')) {
    //   localLookupHub = localStorage.getItem('lookupHub')

    // }

    this.$nextTick(() => {
      if (this.use !== 'search') {
        this.setUpEnv(this.view, localLookupHub)
      }
      // setTimeout(this.setUpEnv(this.view, localLookupHub, localAssort), 1000)
    })

    this.looks = this.store.applications.items
    this.renderedLooks = [].concat(...this.store.applications.items)

    this.scrollablePanel = document.querySelector('.mainPanelGrid')

    this.$nextTick(() => {
      this.scrollablePanel.scrollTop = 0

      // if (this.gridMode === 'list') {
      //   this.scrollablePanel.style.overflowY = 'hidden'
      // } else {
      //   this.scrollablePanel.style.overflowY = 'scroll'
      // }
      this.calculateViewHeight()
      if (this.gridMode === 'list') {
        this.setGridHeight()
      } else {
        this.setGridCardHeight()
      }
      // if (this.gridMode === 'list') {
      //   this.scrollablePanel.style.overflowY = 'hidden'
      //   this.scrollablePanel.scrollTop = 0
      // } else {
      //   this.scrollablePanel.style.overflowY = 'scroll'
      //   this.scrollablePanel.style.height =
      //     this.$refs.mainPanel.offsetHeight - 60 + 'px'
      // }
    })
    window.addEventListener('resize', this.calculateViewHeight)

    // this.scrollablePanel = document.querySelector('.mainPanelGridBottomCard')
    // this.scrollablePanel = document.querySelector('.mainPanelGridBottom')

    // this.store.currentGridView = this.mode
    // this.gridMode = this.store.currentGridView
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateViewHeight)
    this.store.assortUser.assortMetaData.items = 0
    this.store.assortUser.assortMetaData.tops = 0
    this.store.assortUser.assortMetaData.bottoms = 0
    this.store.assortUser.assortMetaData.mens = 0
    this.store.assortUser.assortMetaData.womens = 0
  },
  methods: {
    addSelectedToAssortment() {
      this.store.assortments.addToAssortment(this.store.selectedPC9s.selected)
      this.store.addGlobalAssortment.season = this.store.currentSeason
      this.store.addGlobalAssortment.brand = this.store.currentBrand
      this.store.addGlobalAssortment.year = this.store.currentYear
      this.store.addGlobalAssortment.pc9array =
        this.store.assortments.currentAssortmentList

      const pc9inputStr =
        this.store.assortments.currentAssortmentList.toString()
      this.store.addGlobalAssortment.pc9input = pc9inputStr

      this.confirmAddAssortment()
    },

    applyAffiliate(regionvalue, affiliatevalue) {
      this.store.currentAffiliate = affiliatevalue
      this.store.lookupHub = affiliatevalue
      localStorage.setItem('currAffiliate', affiliatevalue)
      this.store.addGlobalAssortment.affiliate = affiliatevalue
      this.store.addGlobalAssortment.localHub = affiliatevalue
      localStorage.setItem('lookupHub', affiliatevalue)
      localStorage.setItem('currAssortType', this.view)
      if (this.view !== 'personal') {
        this.store.addGlobalAssortment.visibility = 'global'
        localStorage.setItem('currVisibility', 'global')
      }

      this.store.currentRegion = regionvalue
      this.store.addGlobalAssortment.region = regionvalue
      localStorage.setItem('currRegion', regionvalue)
      // this.store.assort.refresh()
      // this.store.regions.refresh()
      // this.store.addGlobalAssortment.refreshRegions()
      // this.store.addGlobalAssortment.refreshAffiliates()
    },

    applyRegion(value) {
      this.store.currentAffiliate = ''
      localStorage.removeItem('currAffiliate')
      this.store.addGlobalAssortment.affiliate = ''
      this.store.currentRegion = value
      this.store.lookupHub = value
      this.store.addGlobalAssortment.region = value
      this.store.addGlobalAssortment.localHub = value
      this.store.addGlobalAssortment.visibility = 'global'

      localStorage.setItem('lookupHub', value)
      localStorage.setItem('currAssortType', this.view)
      localStorage.setItem('currVisibility', 'global')
      localStorage.setItem('currRegion', value)

      if (this.view === 'personal') {
        this.store.userAssortments.refresh()
      } else {
        if (this.view !== 'search') {
          this.store.assort.refresh()
          this.store.regions.refresh()
          this.store.addGlobalAssortment.refreshRegions()
        }
      }
    },
    calculateViewHeight() {
      this.scrollHeight = this.$el.offsetHeight - 40
      if (this.gridMode === 'grid') {
        this.scrollablePanel.style.height = this.scrollHeight - 120 + 'px'
        this.scrollablePanel.style.overflowY = 'scroll'
      } else {
        if (this.gridMode === 'list') {
          this.scrollablePanel.style.height = this.scrollHeight - 120 + 'px'
        } else {
          this.scrollablePanel.style.height = '100%'
        }
        this.scrollablePanel.style.overflowY = 'scroll'
        this.scrollablePanel.scrollTop = 0
      }
    },
    confirmAddAssortment() {
      this.store.addGlobalAssortment.editMode = false
      this.store.addGlobalAssortment.addMode = true
      this.store.addGlobalAssortment.createdAssortID =
        this.store.router.params.assortmentId
      this.$refs.assortform.open()
    },

    confirmNewAssortment() {
      this.store.addGlobalAssortment.pc9status = false
      this.store.assortUser.displayMetaData = false
      this.store.addGlobalAssortment.locked = false
      this.store.addGlobalAssortment.name = ''
      this.store.addGlobalAssortment.pc9input = ''
      this.store.addGlobalAssortment.editMode = false
      this.store.addGlobalAssortment.selectedRows = []
      this.store.addGlobalAssortment.editRows = []
      this.store.addGlobalAssortment.selectedAssortmentsName = []
      if (this.store.router.path === '/assort/my') {
        this.store.addGlobalAssortment.visibility = 'personal'
      } else {
        this.store.addGlobalAssortment.visibility = 'global'
      }
      this.$refs.newassortform.open()
    },

    myClickEvent() {
      this.secondClick = 2
      const elem = this.$refs.gridBtn
      elem.click()
    },

    openCarry() {
      this.mode = 'carryover'
      this.store.addGlobalAssortment.newYear = 'selected'
      this.store.addGlobalAssortment.newSeason = 'selected'
      this.store.addGlobalAssortment.editMode = false
      this.store.addGlobalAssortment.carryOver = true
      this.formFunction = 'Carry Over Assortment'
      this.store.addGlobalAssortment.pc9input = ''
      this.store.addGlobalAssortment.name = this.editRows[0].name
      this.store.addGlobalAssortment.year = this.editRows[0].year
      this.store.addGlobalAssortment.brand = this.editRows[0].brand
      this.store.addGlobalAssortment.season = this.editRows[0].season
      this.store.addGlobalAssortment.createdAssortID =
        this.editRows[0].assortmentId
      this.store.addGlobalAssortment.carryMode = true
      this.$refs.carryassortform.open()
    },
    openCopy() {
      this.mode = 'copy'
      // this.store.addGlobalAssortment.newYear = 'selected'
      // this.store.addGlobalAssortment.newSeason = 'selected'
      this.store.addGlobalAssortment.editMode = false
      this.store.addGlobalAssortment.carryOver = false
      this.formFunction = 'Copy Assortment'
      this.store.addGlobalAssortment.pc9input = ''
      this.store.addGlobalAssortment.name = ''
      // this.store.addGlobalAssortment.name = this.editRows[0].name
      // this.store.addGlobalAssortment.year = this.editRows[0].year
      // this.store.addGlobalAssortment.brand = this.editRows[0].brand
      // this.store.addGlobalAssortment.season = this.editRows[0].season
      this.store.addGlobalAssortment.createdAssortID =
        this.editRows[0].assortmentId
      this.store.addGlobalAssortment.name = `Copy of ${this.editRows[0].name}`
      this.store.addGlobalAssortment.copyMode = true
      this.$refs.copyassortform.open()
    },

    openEdit() {
      this.store.addGlobalAssortment.pc9status = false
      this.store.addGlobalAssortment.carryOver = false
      this.store.assortUser.displayMetaData = false
      this.formFunction = 'Edit Assortment'
      this.mode = 'edit'
      if ('isLocked' in this.editRows[0]) {
        this.store.addGlobalAssortment.locked = this.editRows[0].isLocked
      }
      if ('visibility' in this.editRows[0]) {
        this.store.addGlobalAssortment.visibility = this.editRows[0].visibility
      }
      this.store.addGlobalAssortment.localHub = this.editRows[0].localHub
      localStorage.setItem('lookupHub', this.editRows[0].localHub)
      this.store.addGlobalAssortment.pc9input = ''
      this.store.addGlobalAssortment.name = this.editRows[0].name
      this.store.addGlobalAssortment.year = this.editRows[0].year
      this.store.addGlobalAssortment.brand = this.editRows[0].brand
      this.store.addGlobalAssortment.season = this.editRows[0].season
      this.store.addGlobalAssortment.createdAssortID =
        this.editRows[0].assortmentId
      this.store.addGlobalAssortment.editMode = true
      this.$refs.editassortform.open()
      // this.store.addGlobalAssortment.visibility =
    },

    openPalette() {
      this.$refs.colorpalettemodal.open()
    },

    searchAffiliate(currAffiliate) {
      let localAssortAffiliates = []
      localAssortAffiliates = JSON.parse(
        localStorage.getItem('currAssortAffiliates') || [],
      )
      // if (localStorage.getItem('currAssortAffiliates')) {
      // }
      // let keepGoing = true
      // let finalresult = false
      // while (keepGoing) {
      const searchresult = localAssortAffiliates.some(
        aff => aff === currAffiliate,
      )
      // if (this.affiliateOptions.length >= 1) {
      //   keepGoing = false
      //   finalresult = searchresult
      //   return finalresult
      // }
      // }
      return searchresult
    },
    setGridHeight() {
      if (this.gridMode === 'list') {
        if (this.gridHeight / this.gridHeightWindow >= 0.8) {
          this.scrollablePanel.style.overflowY = 'scroll'
        } else {
          this.scrollablePanel.style.overflowY = 'hidden'
        }
      }
      // if (this.gridHeight > 100) {
      //   return true
      // } else {
      //   return setTimeout(this.setGridHeight, 1000)
      // }
    },
    setGridCardHeight() {
      if (this.gridMode === 'grid') {
        if (this.gridCardHeight / this.gridHeightWindowCard >= 0.7) {
          this.scrollablePanel.style.overflowY = 'scroll'
        } else {
          this.scrollablePanel.style.overflowY = 'hidden'
        }
      }
      // if (this.gridHeight > 100) {
      //   return true
      // } else {
      //   return setTimeout(this.setGridHeight, 1000)
      // }
    },
    // rerenderLoadImages() {
    //   this.store.assortUser.componentKey += 1
    //   this.store.userAssortments.componentKey += 1
    //   this.store.assort.componentKey += 1
    // },
    setUpEnv(view, hub) {
      switch (view) {
        case 'region':
          if (hub === 'none') {
            this.applyRegion('LSUS')
          } else {
            if (this.regionsHelper(hub) === hub) {
              this.applyRegion('LSUS')
            }
          }
          break
        case 'global':
          if (hub !== 'global') {
            this.applyRegion('global')
          }
          break
        case 'affiliate':
          if (hub === 'none') {
            this.applyAffiliate('LSUS', 'US_GDV_ON')
          } else {
            if (this.affiliatesHelper(hub) === hub) {
              if (this.regionsHelper(hub) === hub) {
                this.applyAffiliate('LSUS', 'US_GDV_ON')
              } else {
                const search = this.searchAffiliate(
                  this.store.addGlobalAssortment.affiliate,
                )
                if (search) {
                  this.store.addGlobalAssortment.localHub =
                    this.store.addGlobalAssortment.affiliate
                } else {
                  switch (hub) {
                    case 'LSUS':
                      this.applyAffiliate('LSUS', 'US_GDV_ON')
                      break
                    case 'CNBG':
                      this.applyAffiliate('CNBG', 'INDIA_GDV_AFF')
                      break
                    case 'LSE':
                      this.applyAffiliate('LSE', 'EU')
                      break
                    case 'HONGKONG':
                      this.applyAffiliate('HONGKONG', 'CH_GDV_AFF')
                      break
                    default:
                      this.applyAffiliate('LSUS', 'US_GDV_ON')
                      break
                  }
                }
              }
            } else {
              const search = this.searchAffiliate(
                this.store.addGlobalAssortment.affiliate,
              )
              if (!search) {
                switch (this.store.addGlobalAssortment.region) {
                  case 'LSUS':
                    this.applyAffiliate('LSUS', 'US_GDV_ON')
                    break
                  case 'CNBG':
                    this.applyAffiliate('CNBG', 'INDIA_GDV_AFF')
                    break
                  case 'LSE':
                    this.applyAffiliate('LSE', 'EU')
                    break
                  case 'HONGKONG':
                    this.applyAffiliate('HONGKONG', 'CH_GDV_AFF')
                    break
                  default:
                    this.applyAffiliate('LSUS', 'US_GDV_ON')
                    break
                }
              } else {
                if (
                  this.store.addGlobalAssortment.localHub !==
                  this.store.addGlobalAssortment.affiliate
                ) {
                  this.applyAffiliate(
                    this.store.addGlobalAssortment.region,
                    this.store.addGlobalAssortment.affiliate,
                  )
                }
              }
            }
          }

          break
        case 'personal':
          if (hub !== 'global') {
            this.applyRegion('global')
          }
          break
        default:
          this.applyRegion('global')
          break
      }
    },
    shorten(text, max) {
      return text && text.length > max
        ? text.slice(0, max).split(' ').slice(0, -1).join(' ')
        : text
    },
    sortByModified(looks) {
      const sortedArray = looks.sort(function (a, b) {
        var c = new Date(a.modifyStamp)
        var d = new Date(b.modifyStamp)
        return c - d
      })
      return sortedArray
    },
    toggleMode(mode) {
      localStorage.setItem('gridview', mode)
      this.store.currentGridView = mode
      this.store.addGlobalAssortment.currentGridView = mode
      this.scrollHeight = this.$el.offsetHeight - 40
      this.store.addGlobalAssortment.selectedRows = []
      this.store.addGlobalAssortment.editRows = []
      this.store.addGlobalAssortment.assortMetaDataArray = []
      this.store.assortUser.displayMetaData = false
      this.store.addGlobalAssortment.pc9status = false
      this.store.assortUser.fromGridSelect = false
      this.store.assortUser.fromLanding = false
      this.store.addGlobalAssortment.selectedAssortmentsName = []
      this.store.assortUser.assortMetaData.items = 0
      this.store.assortUser.assortMetaData.tops = 0
      this.store.assortUser.assortMetaData.bottoms = 0
      this.store.assortUser.assortMetaData.mens = 0
      this.store.assortUser.assortMetaData.womens = 0

      if (this.gridMode === 'grid') {
        this.store.assort.componentKey += 1
        this.store.userAssortments.componentKey += 1
        localStorage.setItem('gridview', 'grid')
        this.scrollablePanel.style.height = 'calc(100vh - 100px)'
        this.scrollablePanel.style.overflowY = 'scroll'
        if (this.secondClick < 2) {
          setTimeout(this.myClickEvent, 100)
        }
      } else {
        this.secondClick = 1
        localStorage.setItem('gridview', 'list')
        this.scrollablePanel.style.height = '100%'
        if (this.gridHeight / this.gridHeightWindow >= 0.7) {
          this.scrollablePanel.style.overflowY = 'scroll'
        } else {
          this.scrollablePanel.style.overflowY = 'hidden'
        }
        // this.scrollablePanel.style.overflowY = 'hidden'
        this.scrollablePanel.scrollTop = 0
      }
      this.calculateViewHeight()
    },
    updateAffiliate(affiliatevalue) {
      this.store.currentAffiliate = affiliatevalue
      this.store.lookupHub = affiliatevalue
      localStorage.setItem('currAffiliate', affiliatevalue)
      this.store.addGlobalAssortment.affiliate = affiliatevalue
      this.store.addGlobalAssortment.localHub = affiliatevalue
      localStorage.setItem('lookupHub', affiliatevalue)
      if (this.view !== 'personal') {
        this.store.addGlobalAssortment.visibility = 'global'
        localStorage.setItem('currVisibility', 'global')
      }
      localStorage.setItem('currAssortType', 'affiliate')

      // this.store.assort.refresh()
      // this.store.regions.refresh()
      // this.store.addGlobalAssortment.refreshRegions()
      // this.store.addGlobalAssortment.refreshAffiliates()
      return (this.store.addGlobalAssortment.affiliate = affiliatevalue)
    },
    updateRegion(value) {
      this.store.currentRegion = value
      this.store.addGlobalAssortment.region = value
      if (this.view !== 'personal') {
        this.store.addGlobalAssortment.visibility = 'global'
        localStorage.setItem('currVisibility', 'global')
      }

      localStorage.setItem('currRegion', value)
      if (this.view === 'region') {
        this.store.lookupHub = value
        this.store.addGlobalAssortment.localHub = value
        localStorage.setItem('lookupHub', value)
        localStorage.setItem('currAssortType', 'region')
        // localStorage.setItem('currVisibility', this.view)
        // localStorage.setItem('year', value)
      }
      return (this.store.addGlobalAssortment.region = value)
    },
    updateSeason(value) {
      localStorage.setItem('season', value)
      return (this.store.addGlobalAssortment.season = value)
    },
    updateYear(value) {
      localStorage.setItem('year', value)
      return (this.store.addGlobalAssortment.year = value)
    },
    async openCompare() {
      this.mode = 'compare'
      this.store.addGlobalAssortment.editMode = false
      this.store.addGlobalAssortment.carryOver = false
      this.store.addGlobalAssortment.compare = true
      this.formFunction = 'Assortment Compare'
      this.store.addGlobalAssortment.compareMode = true
      this.compareList = await this.store.addGlobalAssortment.doCompare()
      this.$refs.assortcompare.open()
    },
  },
}
</script>

<style lang="scss">
.assort-wrapper {
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
}

section {
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  position: relative;
  z-index: 2;
  // xmargin-bottom: 120px;
}
.sectionHeight {
  // height: calc(100vh - 80px);
  // margin-bottom: 160px;
}
.sectionHeightList {
  height: 100%;
  .mainPanel {
    height: 100%;
    // min-height: calc(100vh);
    min-height: calc(100vh - 80px);
    align-content: flex-start;
  }
}
.mainPanel.AssortPanel {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: calc(100vh - 80px);
  min-height: calc(100vh - 80px);
  // max-height: calc(100vh);
  // min-height: calc(100vh);
  // max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  // padding: 0 20px;
  .filtersOpen & {
    width: calc(100% - 255px);
    @media screen and (min-width: 1280px) {
      width: calc(100% - 355px);
    }
  }
}
.mainPanelGrid {
  width: 100%;
  overflow: hidden;
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  // max-height: calc(100% - 80px);
  // max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  // margin-bottom: 240px;
  margin-bottom: 120px;
  // padding: 0 20px;
  .filtersOpen & {
    width: calc(100% - 255px);
    @media screen and (min-width: 1280px) {
      width: calc(100% - 355px);
    }
  }
}
.mainPanelGridBottom {
  width: 100%;
  overflow: hidden;
  display: flex;
  // position: fixed;
  top: 80px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  // max-height: calc(100% - 80px);
  // max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  // padding: 0 20px;
}
.mainPanelGridBottomCard {
  width: 100%;
  // overflow: hidden;
  display: flex;
  // position: fixed;
  // height: calc(100vh - 80px);
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  // max-height: calc(100% - 80px);
  // max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  // margin-bottom: 140px;
  // padding: 0 20px;
}
.mainPanelActionsGrid {
  // padding-top: 10px;
  display: flex;
  flex-direction: row;
  padding: 0 10px 10px;
  width: 100%;
  height: 60px;
  & > div {
    &:first-child {
      width: calc(150px);
    }
    &:nth-child(2) {
      width: calc(100% - 150px);
    }
  }
  button {
    line-height: 30px;
    margin-right: 10px;
    font-weight: 500;
  }
  .new-btn {
    svg {
      width: 14px;
      height: 14px;
      line-height: 30px;
      vertical-align: text-top;
      display: inline-block;
    }
  }
}
.mainPanelActionsTop {
  // padding-top: 10px;
  display: flex;
  flex-direction: row;
  padding: 0 0 0px;
  width: 100%;
  height: 30px;
  & > div {
    &:first-child {
      width: calc(150px);
    }
    &:nth-child(2) {
      width: calc(100% - 150px);
    }
  }
  button {
    line-height: 30px;
    margin-right: 10px;
    font-weight: 500;
  }
  .new-btn {
    svg {
      width: 14px;
      height: 14px;
      line-height: 30px;
      vertical-align: text-top;
      display: inline-block;
    }
  }
}
.panelActionFilter.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.filterToggle {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  .disabled & {
    color: #888;
    opacity: 0.75;
  }
  svg {
    height: 16px;
    display: block;
    width: auto;
  }
}
.mainPanelFilters {
  width: calc(0px);
  position: relative;
  padding: 0px 0 0;
  // transition: all 0.5s;
  z-index: 1;
  max-height: 100%;
  &.openFilters {
    width: 255px;
    @media screen and (min-width: 1280px) {
      width: 355px;
    }
    // border-right: 1px solid black;
  }
}
.mainPanelGrid {
  width: 100%;

  &.openFilters {
    width: calc(100% - 255px);
    @media screen and (min-width: 1280px) {
      width: calc(100% - 355px);
    }
  }
  &Title {
    position: sticky;
    top: 0;
    z-index: 300;
    background-color: white;
  }
}

.mainPanelActionButtonsAssort {
  // padding-right: 16px;
  button {
    font-size: 15px;
    &.active {
      color: rgb(50, 197, 255);
    }
    &.cancel {
      svg {
        transform: rotate(45deg);
      }
    }
  }
  .addSelected {
    border: 1px solid black;
    padding: 0 5px;
    font-size: 12px;
  }
  & div {
    padding: 5px 5px;

    &.inactive {
      // opacity: 0.5;
      color: black;
    }
    &.disabled {
      opacity: 0.75;
      color: #ccc;
      pointer-events: none;
    }
    &.active {
      opacity: 1;
      color: rgb(50, 197, 255);
    }
    svg {
      width: 16px;
      display: block;
      height: 16px;
      cursor: pointer;
      margin-top: 4px;
    }
  }
}
.tooltip .tooltip-text {
  visibility: hidden;
  text-align: center;
  padding: 2px 6px;
  position: absolute;
  z-index: 100;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}
.dropdownSpan {
  width: 150px;
}
</style>
