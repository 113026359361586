<template>
  <div :class="screenSize">
    <div :class="screenSizeGrid" @click="openApp(appId)">
      <span class="h-1/2 bg-card-gray">
        <div class="p-6 rounded-md" @click="openApp(appId)">
          <img
            style="width: 90%; height: 90%; position: absolute"
            src="/images/login-background.jpg"
          />
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { consumerProduct } from '../../util/valueHelpers'

export default {
  name: 'CardGridPreview',
  props: {
    appId: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      backImage: null,
      checkedValue: false,
      consumerProduct: consumerProduct,
      currentSide: 'front',
      // editRows: [],
      frontError: false,
      frontImage: null,
      ImageCheckComplete: false,
      isLoaded: false,
      loopCount: 0,
      observer: null,
      pc9Images: [],
      rowData: [],
      // selectedRows: [],
    }
  },
  computed: {
    cardElementClass() {
      return 'h-1/2'
    },
    screenSize() {
      if (this.windowHeight < 961) {
        return `userMode flex flex-col relative LookPreviewCard rounded-lg cursor-pointer bg-card-gray h-screen/2 ${
          this.nonAdopted ? 'high-light-not-adopted' : ''
        }`
      }
      return `userMode flex flex-col relative LookPreviewCard rounded-lg cursor-pointer bg-card-gray h-screen/2 ${
        this.nonAdopted ? 'high-light-not-adopted' : ''
      }`
    },
    screenSizeGrid() {
      if (this.windowHeight < 961) {
        return 'grid pb-2 grid-cols-2 outline-none h-screen/2 gridMax bg-card-gray'
      }
      return 'grid grid-cols-2 pb-2 outline-none h-screen/2 gridMax bg-card-gray'
    },
  },
  inject: ['store'],
  mounted() {
    this.observer = new IntersectionObserver(this.showComponent, {
      threshold: 1,
      root: document.querySelector('.mainPanelGrid'),
    })
    this.observer.observe(this.$el)
    this.currentSide = this.side
  },
  beforeDestroy() {
    this.clearEditSelectRows()
  },
  methods: {
    async myFetch() {
      this.store.assortUser.fromGridSelect = true
      this.store.assortUser.refresh()
    },
    clearEditSelectRows() {
      this.store.addGlobalAssortment.selectedRows = []
      this.store.addGlobalAssortment.editRows = []
    },
    openApp(appId) {
      localStorage.setItem('currAppName', this.appId)
      const urlRedirect = `/application`
      setTimeout(function () {
        location.replace(urlRedirect)
      }, 10)
    },
  },
}
</script>
<style lang="scss">
.ThumbnailWrapper {
  position: relative;
  padding: 0 0px;
}
.ThumbnailFlip {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 20px;
  // background: rgba(0, 0, 0, 0.4);
  flex-direction: row;
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 20px;
  padding: 0 4px;
  z-index: 1;
  cursor: pointer;
  // opacity: 0;
  transition: all 0.25s;
  &.back {
    left: calc(100% - 22px);
  }
}
.LookPreviewCard {
  overflow: hidden;
  max-height: 275px;
  max-width: 300px;
  border: 1px solid rgb(151, 151, 151);
  .ThumbnailFlip {
    opacity: 0;
  }
  &:hover {
    .ThumbnailFlip {
      opacity: 1;
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
  &.canvasMode .ThumbnailFlip {
    display: none !important;
  }
}
.editActions {
  display: flex;
  padding: 5px 5px;
  background-color: #ccc;
  color: #888;
  position: absolute;
  top: 0;
  width: 100%;
  align-content: center;
  justify-content: space-between;
  line-height: 20px;
  // font-size: 16px;
  .handle {
    padding: 0 5px;
    cursor: move;
    span {
      position: relative;
      // width: 2px;
      // background-color: #888;
      color: #888;
      display: inline-block;
      line-height: 20px;
      &:last-child {
        padding-left: 15px;
        font-size: 12px;
      }
    }
  }
}

@media (hover: hover) {
  .previewData {
    // display: none;
    // height: 0;
    overflow: hidden;
    transition: all 0.25s;
    // border: 0;
    bottom: 1px;
    opacity: 1;
    display: flex;
    // justify-content: center;
    .editMode & {
      bottom: auto;
      left: auto;
    }
  }
}

.LookPreviewCard {
  &.editMode {
    border: 1px solid #000;

    .ThumbnailWrapper {
      border: 0;
    }
    &:hover {
      .previewData {
        display: flex !important;
        opacity: 1;
        bottom: auto;
      }
    }
  }
}

.td,
.carryover {
  // width: 30px;
  text-align: center;
  position: absolute;
  pointer-events: none;
}
.td {
  bottom: 5px;
  right: 5px;
  //background-color: #e02020;
  // background-color: darkblue;
  // background-color: #c423cc;
  // background: #c41320;
  color: black;
  padding: 5px 5px;
  // border-radius: 5px;
  font-size: 9px;
  line-height: 12px;
  font-weight: bold;
  bottom: 0;
  z-index: 200;
  // border: 1px solid #979797;
  .userMode & {
    right: 5px;
  }
}
.tags {
  position: absolute;
  top: 0px;
  left: 5px;
  // background-color: darkred;
  // font-size: 12px;
  color: white;
  font-weight: bold;
  padding: 5px 5px 5px 0;
  line-height: 12px;
  z-index: 4;
  border-radius: 4px;
  flex-direction: column;
  display: flex;
  > div {
    margin-bottom: 5px;
    // border-radius: 5px;
  }
  > div.co {
    // background: url(/images/ico-blue-flag.svg);
    background: #c41320;
    // background-position: center left;
    padding: 0px 7px 0px 7px;
    line-height: 24px;
    font-size: 9px;
    background-repeat: no-repeat;
  }
}
.canvasMode {
  border: 0 !important;
  .td,
  .tags,
  .ThumbnailFlip {
    display: none !important;
  }
  .previewData,
  &:hover .previewData {
    display: none !important;
  }
}
.SpanBorderOuter {
  border: 1px solid rgb(255, 255, 255);
  min-height: 150px;
  // background-color: white;
}
.SpanBorderOuterThree {
  border: 1px solid rgb(255, 255, 255);
  min-height: 167px;
  // background-color: white;
}
.SpanBorderInner {
  border: 1px solid rgb(255, 255, 255);
  min-height: 75px;
  // background-color: rgb(255, 255, 255);
}
.gridMax {
  max-height: 368px;
}

.AddDiv {
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: white;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  line-height: 14px;
  text-align: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
  font-size: 20px;
  z-index: 5;
  cursor: pointer;
}

.AddDivLeft {
  position: absolute;
  top: 3px;
  left: 3px;
  // background-color: white;
  // border-radius: 4px;
  width: 16px;
  height: 16px;
  line-height: 14px;
  text-align: center;
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
  font-size: 16px;
  z-index: 5;
  cursor: pointer;
}

.checkbox-input input[type='checkbox'] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  // border: 1px solid #f9f8f7;
  border-radius: 4px;
}
.checkbox-input input[type='checkbox']:checked {
  background-color: #0094ec;
  // border: 1px solid #ff684f !important;
  background-image: url('~@/assets/check.png');
  background-size: 75%;
  background-position: center center;
  background-repeat: no-repeat;
}
.checkbox-input {
  display: inline-block;
  // padding: 5px 0 10px 0;
}
.checkbox-input input[type='checkbox'] {
  // border: 1px solid #999;
  width: 16px;
  height: 16px;
  // margin-right: 2px;
  display: inline-block;
  border-radius: 4px;
  // background-color: white;
}

/* Remove the ugly default outline */
.checkbox-input input[type='checkbox']:focus {
  outline: none !important;
}
.high-light-not-adopted {
  border: 2px solid rgba(255, 187, 0, 0.5);
}
</style>

.AddDiv { position: absolute; .AddDiv { position: absolute; top: 3px; right:
3px; background-color: white; border-radius: 4px; width: 16px; height: 16px;
line-height: 14px; text-align: center; box-shadow: 0px 1px 2px rgba(0, 0, 0,
0.4); font-size: 20px; z-index: 5; cursor: pointer; } text-align: center;
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4); font-size: 20px; z-index: 5; cursor:
pointer; }
