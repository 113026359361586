<template>
  <div :key="$route.fullPath" class="LooksGridCard w-full mb-64 pt-10">
    <div
      v-if="assortments.length === 0 && isReady"
      class="w-full flex flex-col items-center justify-center"
    >
      <div class="font-bold text-xlg pb-6">No assortments to display.</div>
      <div class="text-gray-medium text-xlg">
        Use CREATE ASSORTMENT above to add an assortment for this season and
        year.
      </div>
    </div>
    <template v-else>
      <div
        v-if="!isReady"
        class="gridScroll flex w-full h-full justify-center align-center"
      >
        <Spinner />
      </div>
      <div
        class="w-full h-full gridScroll"
        :class="{ gridScrollList: mode === 'list' }"
      >
        <div class="h-full mb-64">
          <div class="h-full w-full">
            <div ref="gridCardBox" class="preview-wrapper">
              <div>
                <div v-for="(app, index) in renderedAssortments" :key="index">
                  <CardGridPreview
                    :key="`card-assortment_${componentKey}`"
                    :app-name="app.name"
                    :app-id="app.name"
                    :total-looks="app.length"
                  />
                  <div
                    class="text-center previewData cursor-pointer"
                    @click="openApp(app.name)"
                  >
                    <div class="p-2 text-2xl font-semibold cursor-pointer">
                      {{ app.name }}
                      <br />
                      {{ app.status }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Spinner from '../Spinner.vue'
import CardGridPreview from './CardGridPreview'

export default {
  name: 'CardGrid',
  components: { CardGridPreview, Spinner },

  props: {
    mode: {
      type: String,
      default: 'grid',
    },
    submode: {
      type: String,
      default: 'view',
    },
    assortments: {
      type: Array,
      default: () => [],
    },
    use: {
      type: String,
      required: true,
      default: '',
    },
    sorted: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    side: 'front',
    currentPage: 1,
    currentPageSize: 24,
    renderedAssortments: [],
    scrollableEl: null,
    dragging: false,
    isSearching: false,
    searchTerm: '',
    // selectedPC9s: [],
  }),
  computed: {
    assortSeason() {
      return this.store.addGlobalAssortment.season
    },
    assortYear() {
      return this.store.addGlobalAssortment.year
    },
    componentKey() {
      if (this.use === 'line') {
        return this.store.userAssortments.componentKey
      } else {
        if (this.use === 'search') {
          return this.store.searchAssortments.componentKey
        } else {
          return this.store.assort.componentKey
        }
      }
    },
    isReady() {
      if (this.use === 'line') {
        return this.store.userAssortments.isReady
      } else {
        if (this.use === 'search') {
          return this.store.searchAssortments.isReady
        } else {
          return this.store.assort.isReady
        }
      }
    },
    isSearch() {
      if (this.store.router.path === '/search') {
        return true
      }
      return false
    },
    // allPC9s() {
    //   if (this.use === 'line') {
    //     return this.store.userAssortments.allPC9s
    //   } else {

    //     return this.store.assort.allPC9s
    //   }
    // },
  },
  inject: ['store'],

  watch: {
    isReady(newVal) {
      if (newVal) {
        this.renderedAssortments = this.assortments
      }
    },
    looks(newArray, oldArray) {
      this.renderedAssortments = [].concat(newArray)
      this.currentPage = 1
      if (this.use === 'line') {
        this.renderedAssortments = newArray.slice(
          0,
          this.currentPageSize * this.currentPage,
        )
      }
      // this.currentPageSize = 16
      this.$nextTick(() => {
        if (this.mode === 'grid') {
          this.scrollableEl = document.querySelector('.mainPanelGrid')
          this.scrollableEl.scrollTop = 0
          this.scrollableEl.style.overflowY = 'hidden'
          this.scrollableEl.style.overflowY = 'scroll'
        }
        // if (this.use === 'line') {
        //   this.scrollableEl.removeEventListener('scroll', this.scrollListener)
        //   this.scrollableEl.addEventListener('scroll', this.scrollListener)
        // }
      })
      /* */
    },
    assortments(newArray, oldArray) {
      this.renderedAssortments = [].concat(newArray)
      this.currentPage = 1
      if (this.use === 'line') {
        this.renderedAssortments = newArray.slice(
          0,
          this.currentPageSize * this.currentPage,
        )
      }
      // this.currentPageSize = 16
      this.$nextTick(() => {
        if (this.mode === 'grid') {
          this.scrollableEl = document.querySelector('.mainPanelGrid')
          this.scrollableEl.scrollTop = 0
          this.scrollableEl.style.overflowY = 'hidden'
          this.scrollableEl.style.overflowY = 'scroll'
        }
        // if (this.use === 'line') {
        //   this.scrollableEl.removeEventListener('scroll', this.scrollListener)
        //   this.scrollableEl.addEventListener('scroll', this.scrollListener)
        // }
      })
      /* */
    },
    searchTerm(newVal, oldVal) {
      // this.renderedAssortments = this.assortment.filter()

      this.applySearch(newVal)
    },
    currentPage(newVal) {
      /* */
      this.renderedAssortments = this.assortment.slice(
        0,
        this.currentPageSize * this.currentPage,
      )
      /* */
    },
    mode(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.currentPage = 1
        if (this.scrollableEl) {
          if (newVal === 'list' && this.scrollableEl) {
            this.scrollableEl.style.overflowY = 'hidden'
          } else {
            this.scrollableEl.style.overflowY = 'scroll'
          }
        }
      }
    },
  },
  mounted() {
    if (this.isReady) {
      this.renderedAssortments = this.assortments
    }
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
      // if (this.store.addGlobalAssortment.preSelectedAssortId.length >= 8) {
      //   this.selectAssortNodes()
      // }
    })
  },
  methods: {
    getAssortPC9s(previewPC9) {
      let myArr = previewPC9
      for (let i = 0; i < 7; i++) {
        if (myArr[i] === undefined) {
          myArr.push('noimage')
        }
      }
      return myArr
    },
    getGridCardHeight() {
      const grboxht = this.$refs.gridCardBox?.clientHeight
      // const grboxhtel = this.$refs.gridBox.$el.clientHeight
      this.store.assort.gridCardHeight = grboxht
      this.store.assort.windowHeightSavedCard = this.windowHeight
    },
    onResize() {
      setTimeout(this.getGridCardHeight, 1000)
      setTimeout(this.onResizeNext, 2000)
    },
    onResizeNext() {
      setTimeout(this.getGridCardHeight, 1000)
      // setTimeout(this.getGridHeight, 2000)
    },
    openApp(name) {
      return (window.location.href = `/application`)
    },

    scrollListener(event) {
      if (
        this.scrollableEl.scrollTop + this.scrollableEl.clientHeight ===
        this.scrollableEl.scrollHeight
      ) {
        this.currentPage += 1
      }
    },
    toggleMode(mode) {
      this.mode = mode
    },
    togglePC9ToAdd(event) {
      if (event.target.checked === true) {
        // this.selectedPC9s.push(event.target.value)
        this.store.selectedPC9s.addPC9(event.target.value)
      } else {
        this.store.selectedPC9s.removePC9(event.target.value)
      }
    },
    applySearch(term) {
      const tmpAssortments = []
      const termLowered = term.toLowerCase()
      if (term.length > 0) {
        this.assortments.forEach(assortment => {
          let hasPushed = false
          const id = assortment.assortmentId.toLowerCase()
          const name = assortment.name.toLowerCase()
          const createdBy = assortment.createdBy.toLowerCase()
          if (id.indexOf(termLowered) >= 0) {
            tmpAssortments.push(assortment)
            hasPushed = true
          }
          if (!hasPushed) {
            if (name.indexOf(termLowered) >= 0) {
              tmpAssortments.push(assortment)
              hasPushed = true
            }
          }
          if (!hasPushed) {
            if (createdBy.indexOf(termLowered) >= 0) {
              tmpAssortments.push(assortment)
              hasPushed = true
            }
          }
        })
        this.renderedAssortments = tmpAssortments
      } else {
        this.renderedAssortments = [...this.assortments]
      }
    },
  },
}
</script>

<style lang="scss">
.FrontBackToggle {
  bottom: 20px;
  z-index: 50;
}

.LooksGridCard {
  height: calc(100%);
  // margin-bottom: 120px;
  .preview-wrapper {
    // @apply flex-grow flex items-center justify-center;
    margin-bottom: 120px;
    > div {
      display: grid;
      grid-gap: 30px;
      // grid-template-columns: repeat(auto-fit, 200px);
      grid-template-columns: repeat(auto-fit, 200px);
      @media screen and (min-width: 960px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 1441px) {
        // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        // grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      .ThumbnailWrapper {
        height: 222px;
        height: 222px;
      }
    }
  }
}
.gridScroll {
  padding: 0 20px;
  margin-bottom: 120px;
  &.gridScrollList {
    padding: 0 20px 20px 0;
  }
}
.Add {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white;
  // border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
  font-size: 22px;
  z-index: 5;
  cursor: pointer;

  input {
    cursor: pointer;
    display: block;
    appearance: none;
    webkit-appearance: none;
    width: 16px;
    height: 16px;
    line-height: 16px;
    //visibility: hidden;
    opacity: 0;
  }
  label {
    position: relative;
    span {
      display: none;
    }
    input:checked ~ span {
      // content: '\2713';
      font-family: 'Arial';
      font-weight: 300;
      background-color: #1093dc;
      // border-radius: 50%;
      display: block;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 10;
      padding-left: 1px;
      width: 16px;
      height: 16px;
      color: white;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      pointer-events: none;
    }
  }
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.previewData {
  display: block;
  // position: absolute;
  // height: 50px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  color: black;
  z-index: 100;
  // bottom: 1px;
  // top: auto;
  // left: 1px;
  // font-size: 12px;
  overflow: hidden;
  // border: 1px solid #ccc;
  padding: 0px 0;
  /*
  .editMode & {
    bottom: auto;
    left: auto;
    position: relative;
    opacity: 1;
    display: flex;
  } */
}
// .square-container {
//   position: relative;
//   padding-bottom: 100%;
//   width: 37vh;
//   height: 37vh;
// }
</style>
